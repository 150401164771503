body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    font-size: 13px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root,
.App {
    height: 100%;
}

/** CALENDAR **/
.App .rbc-row-segment .rbc-event-content {
    overflow: visible;
}

.App .rbc-event:focus {
    outline: none;
    box-shadow: 0 0 1px 1px #3b99fc;
}

.rbc-event {
    background-color: transparent !important;
}

/** DROP ZONE **/

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
}

.dropzone:focus {
    border-color: #2196f3;
}

.dropzone.disabled {
    opacity: 0.6;
}
