html {
    height: 100%;
}

.App .Cal__Header__root {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.App .Cal__Today__root .Cal__Today__chevron {
    margin-left: 60px;
    margin-top: -7px;
}

.App .sideCalendar {
    margin-top: -65px;
}

/** Change rounded selectors to squared ones **/

.App .Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
    border-radius: 0;
}

.App .Cal__Day__root.Cal__Day__today:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
    border-radius: 0;
}

.App .Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before,
.App .Cal__Day__root.Cal__Day__enabled:active:before,
.App .Cal__Day__root.Cal__Day__enabled:hover:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
    border-radius: 0;
}

/** End reset rouded selector **/
